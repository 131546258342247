
.WelcomeSection__main-section-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 95vh;
    /* border: .1px solid white; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 6em;
    margin-top: 6em;
  
}




/*============== CARD DESIGN ============*/

.WelcomeSection__main-card-info-container {
    display: inline-flex;
    padding: 1.875rem 3.125rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    /* border: 3px solid red; */
    /* background: rgba(255, 255, 255, 0.50); */
}

    .WelcomeSection__main-title-container {
        display: flex;
        width: 33.625rem;
        height: 12.1875rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
    }
        .WelcomeSection__main-title-container h2 {
            color: #E66931;
            font-family: Montserrat;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            
        }


    .WelcomeSection__main-body-container{
        display: flex;
        width: 33.625rem;
        height: 10.8125rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
        flex-shrink: 0;
    }
        .WelcomeSection__main-body-container p {
            color: #00030A;
            font-family: Lato;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem; 
        }
            .WelcomeSection__main-body-container p span {
                color: #00030A;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: 2.25rem;
            }

    .WelcomeSection__main-button-container{
        display: flex;
        padding: 1.25rem 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        border-radius: 1.25rem;
        border: none;
        background:  #B20918;
    }
        .WelcomeSection__inner-button-container{
            display: flex;
            width: 12.40713rem;
            height: 1.125rem;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }

        .WelcomeSection__inner-button-container a{
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
            text-transform: uppercase;
            text-decoration: none;
        }

        .WelcomeSection__inner-button-container a:hover{
            color: white;
        }



/*==================== RESPONSIVE =========================*/


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        background-size: contain;
        background-position: top;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    } 
    .WelcomeSection__main-card-info-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: auto;
        height: 27em;
        margin: 0;
        margin-top: 15em;
        padding: 1.3em;
        align-items: center;
            
    }
    .WelcomeSection__main-title-container{
        /* border: 2px solid blue; */
        width: auto;    
    }
        .WelcomeSection__main-title-container h2 {
            color: #E66931;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            
            /* line-height: normal; */
            
        }
        
        .WelcomeSection__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .WelcomeSection__main-body-container p {
                color: #00030A;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 150% */
            }
                .WelcomeSection__main-body-container p span {
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem;
                }

            .WelcomeSection__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
                color: white;
            }
           
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .WelcomeSection__main-section-container{
        /* border: 6px solid rgb(0, 0, 0); */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    } 
    .WelcomeSection__main-card-info-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: auto;
        height: 27em;
        margin: 0;
        margin-top: 3em;
        gap: 0.625rem;
        padding: 1.6em;
        align-items: center;
        
        
        
    }
    .WelcomeSection__main-title-container{
        /* border: 2px solid blue; */
        width: 100%;
        margin: 0;
        padding: 0;
           
    }
        .WelcomeSection__main-title-container h2 {
            color: #E66931;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            padding: 0;
            /* border: 2px solid red; */
            margin: 0;
            
            /* line-height: normal; */
            
        }
        
        .WelcomeSection__main-body-container{
            /* border: 2px solid green; */
            width: auto;
            height: auto;
        }
            .WelcomeSection__main-body-container p {
                color: #00030A;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 150% */
            }
                .WelcomeSection__main-body-container p span {
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem;
                }
            .WelcomeSection__inner-button-container a{
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 100% */
               
            }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .WelcomeSection__main-title-container h2 {
        font-size: 2.5em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .WelcomeSection__main-title-container h2 {
        font-size: 2.5em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .WelcomeSection__main-card-info-container{
        /* border: 3px solid green; */
    }
    .WelcomeSection__main-title-container{
        /* border: 3px solid red; */
    }
    .WelcomeSection__main-body-container{
        /* border: 3px solid black; */
    }
    .WelcomeSection__main-body-container div{
        /* border: 3px solid yellow; */
        width: 90%;
    }
    .WelcomeSection__main-title-container h2 {
        font-size: 2.5em;
        font-weight: 800;
        
    }
 }