* {
    box-sizing: border-box;
  }
.SubscriptionCard__container{
    margin-right: 2em;
  }
      .SubscriptionCard__card-container {
          /* border: 2px solid red; */
          display: inline-flex;
          padding: 1.875rem 2.8125rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 1.4em;
          /* gap: 1.25rem; */
          border-radius: 1.25rem;
          background: #FFF;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
          .SubscriptionCard__card-title h1{
              color: #E66931;
              text-align: center;
              font-family: Montserrat;
              font-size: 3rem;
              font-style: normal;
              font-weight: 700;
              line-height: 1.05rem;
              text-transform: uppercase;
              margin-bottom: .2em;
              margin-top: .3em;
              /* border: 2px solid red; */
          } 
          .SubscriptionCard__card-trial-info{
            /* border: 2px solid red; */
            margin-top: 2em;
          }
            .SubscriptionCard__card-trial-info p{
                margin-bottom: 0;
                color: #000;
                font-family: Lato;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.6rem;
                color: rgb(0, 0, 0);
            }
    
          .SubscriptionCard__card-price{
              margin-top: 3em;
              /* border: 3px solid red; */
  
          }
              .SubscriptionCard__dolar-price{
                  color: #D6D6D6;
                  text-align: right;
                  font-family: Montserrat;
                  font-size: 4.5rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem; /* 17.5% */
                  text-transform: uppercase;
                  /* border: 2px solid black; */
              }
              .SubscriptionCard__cents-price{
                  color: #D6D6D6;
                  font-family: Montserrat;
                  font-size: 2rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem; /* 46.667% */
                  text-transform: uppercase;
                  /* border: 2px solid red; */
                  margin-bottom: 3em;
              
              }
  
  
          .SubscriptionCard__card-description{
              /* border: 3px solid yellow; */
          }
              .SubscriptionCard__card-description p{
                  color: #000;
                  text-align: center;
                  font-size: 1.6rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
                  /* border: 2px solid purple; */
              }
  
  
          .SubscriptionCard__button-container{
              display: flex;
              flex-direction: row;
              justify-content: center;
              /* border: 2px solid green; */
              width: 80%;
          }
              .SubscriptionCard__button{
                  width: 100%;
                  /* border: 2px solid red; */
               

              }
                  .SubscriptionCard__button button{
                      width: 100%;
                      background-color: rgba(255, 255, 255, 1);
                      border: 1px solid #B20918;
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: Montserrat;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                      color: #B20918;
                  }
                  .SubscriptionCard__button button:hover{
                      width: 100%;
                      background-color: #B20918;
                      color:#ffffff;
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: Montserrat;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                  }
  
                  .SubscriptionCard__subscription-definition-container{
                      /* border: 9px solid green; */
                      padding: 0;
                      margin: 0;
                      max-width: 22em;
                  
                  }
                      .SubscriptionCard__subscription-definition-container p{
                              margin-top: .4em;
                              color: #00030A;
                              font-family: Lato;
                              font-size: 1rem;
                              font-style: normal;
                              font-weight: 300;
                              line-height: 2.25rem; 
                      }
  
  
  
  
  /*=========== RESPONSIVE ============*/
  
  @media (max-width: 575.98px) {
  
      .SubscriptionCard__container{
          padding: 0;
          margin: 0;
        }
  }
  
  
  @media (min-width: 576px) and (max-width: 767.98px) {
      .SubscriptionCard__container{
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          /* border: 2px solid red; */
          margin-right: 1.5em;
          margin-left: 1.5em;
          /* border: 6px solid yellow; */
          height: auto;
      }
          .SubscriptionCard__card-container{
              /* border: 2px solid blue; */
              width: auto;
              height: auto;
              
          }
  }
  
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionCard__container{
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 4px solid red; */
        margin-right: 1.5em;
        margin-left: 1.5em;
        /* border: 6px solid yellow; */
        
    }
    .SubscriptionCard__card-container{
        /* border: 2px solid blue; */
        width: 21em;
        height: auto;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionCard__container{
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 4px solid red; */
        margin-right: 1.5em;
        margin-left: 1.5em;
        /* border: 6px solid yellow; */
        
    }
    .SubscriptionCard__card-container{
        /* border: 2px solid blue; */
        width: 21em;
        height: auto !important;
    }
  }
  
  
  @media (min-width: 1200px) {
    .SubscriptionCard__container{
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 4px solid red; */
        margin-right: 1.5em;
        margin-left: 1.5em;
        /* border: 6px solid yellow; */
        
    }
    .SubscriptionCard__card-container{
        /* border: 2px solid blue; */
        max-width: 30em;
        height: auto;
    }
        
  
  }